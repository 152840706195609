import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({providedIn: 'root'})

export class ErrorPopupService  {
  constructor() { }
  showError(err:any){
    if (err && err.error && err.error.message) {
      Swal.fire({
        title: err.error.message,
        confirmButtonText: 'Ok',
        icon: "error",
      }).then((result) => {})
    }
  }
}