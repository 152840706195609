import { NgModule} from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { BehaviorSubject, of } from 'rxjs';
import { RestApiService } from './shared/services/rest-api.service';
import Swal from 'sweetalert2';
@NgModule({
    imports: [],
    providers: [],
    declarations: []
})

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private router: Router,
        public authService: RestApiService
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const bearer = localStorage.getItem('gs_token');
        const mocked = sessionStorage.getItem('mockedUser');
        var mockedUser = JSON.parse(sessionStorage.getItem('mockedUser'));
        let _req;
        if ( bearer ) {
            // console.log('user is logged in, use Bearer auth ');
            if (req.headers.get('tp') == 'multipart/form-data') {
                _req = req.clone( {
                    setHeaders: {
                        'authorization' : 'Bearer ' + bearer
                    }
                });
            }else if(req.method == 'PUT' || req.method == 'POST'){
                _req = req.clone({
                    setHeaders: {
                        'authorization': 'Bearer ' + bearer,
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                });
            }else if(req.method == 'GET'){
                _req = req.clone({
                    setHeaders: {
                        'authorization': 'Bearer ' + bearer
                    }
                });
            }else{
                _req = req.clone({
                    setHeaders: {
                        'authorization': 'Bearer ' + bearer,
                        'Content-Type': 'application/json;'
                    }
                });
            }
            if (mocked) {
                _req = _req.clone({
                    headers: _req.headers.set('switch-user', 'true')
                            .set('switch-user-id', mockedUser.userId)
                            .set('switch-user-name',  mockedUser.userName)
                });
            }
           
        } else {
            if(req.method == 'GET'){
                _req = req.clone( {
                    setHeaders: {
                        'method' : req.method
                    }
                });
            }else{
                // only for login url we have to send application/x-www-form-urlencoded for other all apis send in json format
                let loginurl = false;
                if (req.url.toLowerCase().indexOf('login') > 0) {
                    localStorage.removeItem('gs_token')
                    localStorage.removeItem('gs_verified')
                    loginurl = true;
                }
                _req = req.clone( {
                    setHeaders: {
                        'method' : req.method,
                        'Authorization': 'Basic ' + btoa('gs-web:gs-web'),
                        'Content-Type': (loginurl) ? 'application/x-www-form-urlencoded' : 'application/json;charset=UTF-8'
                    }
                });
            }
        }
        if (req.headers.get('setbearer') == 'no-bearer') {
            if (req.method == 'PUT' || req.method == 'POST') {
                _req = req.clone({
                    setHeaders: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                });
            } else {
                _req = req.clone({
                    setHeaders: {}
                });
            }
        }
        return next.handle(_req)
            .do((event: HttpEvent<any>) => {
                if ( event instanceof HttpResponse ) {
                    if ( !bearer ) {
                        const headers = event.headers;
                        const access_token = headers.get('access_token');
                        const refresh_token = headers.get('refresh_token');
                        if (access_token) {
                            localStorage.setItem('gs_token', access_token);
                            localStorage.setItem('gs_refresh_token', refresh_token);
                        }
                    }
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 ) {
                        localStorage.removeItem('gs_token');
                        localStorage.removeItem('gs_verified');
                        this.handle401Error(_req, next);
                    } else {
                        return Observable.throw(err);
                    }
                }
            });
    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            this.authService.refreshToken().subscribe(
                data => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(data);
                }, err => {
                    if (err.status === 401 || err.status === 400) {
                        localStorage.removeItem('gs_refresh_token');
                        this.router.navigate(['/login']);  
                        Swal.fire({
                            title: 'Your session is timed out. Please login again.',
                            confirmButtonText: 'Ok',
                            icon: "error",
                        }).then((result) => { })
                    }
                }
            );
        } 
    }
}
