import { Component, OnInit } from '@angular/core';
import { RestApiService } from './shared/services/rest-api.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    public restApi: RestApiService,
    public router: Router
  ) { }
  ngOnInit() {
    let url = environment.gsinfo + '/app-message';
    this.restApi.getCall(url).subscribe(
      data => {
        if (data && data.length >0) {
            data.forEach(element => {
              if (element.appMessageType == "ADMIN") {
                sessionStorage.setItem('admin_alert', element.appMessage);
              } else if (element.appMessageType == "APPLICATION_DOWN") {
                //to do redirection to maintanance page.
                this.router.navigate(['/maintenance']);
              }else{
                sessionStorage.removeItem('admin_alert');
              }
            });
        }
      }, err => {
      }
    );
  }
}