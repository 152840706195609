export const environment = {
  production: false,

  authURL: 'https://ship.api.globeship.ca/gs-auth',
  adminUrl: 'https://ship.api.globeship.ca/gs-admin',
  gsorder: 'https://ship.api.globeship.ca/gs-orders',
  gsconsumer: 'https://ship.api.globeship.ca/gs-consumer',
  gsworkorder: 'https://ship.api.globeship.ca/gs-workorder',
  gsinfo: 'https://ship.api.globeship.ca/gs-info'

  //authURL: 'https://stg.api.globeship.com/gs-auth',
 // adminUrl: 'https://stg.api.globeship.com/gs-admin',
 // gsorder: 'https://stg.api.globeship.com/gs-orders',
 // gsconsumer: 'https://stg.api.globeship.com/gs-consumer',
 // gsworkorder: 'https://stg.api.globeship.com/gs-workorder'
  /* Start scope Commentted by Pradip   2022-12-15
  authURL: 'http://gs-stage-alb-1671826559.us-west-2.elb.amazonaws.com/gs-auth',
  adminUrl: 'http://gs-stage-alb-1671826559.us-west-2.elb.amazonaws.com/gs-admin',
  gsorder: 'http://gs-stage-alb-1671826559.us-west-2.elb.amazonaws.com/gs-orders',
  gsconsumer: 'http://gs-stage-alb-1671826559.us-west-2.elb.amazonaws.com/gs-consumer',
  gsworkorder: 'http://gs-stage-alb-1671826559.us-west-2.elb.amazonaws.com/gs-workorder'
  //End scope Commentted by Pradip   2022-12-15 
  */
  /*authURL: 'http://gs-prod-alb-1717206821.us-west-2.elb.amazonaws.com/gs-auth',
  adminUrl: 'http://gs-prod-alb-1717206821.us-west-2.elb.amazonaws.com/gs-admin',
  gsorder: 'http://gs-prod-alb-1717206821.us-west-2.elb.amazonaws.com/gs-orders',
  gsconsumer: 'http://gs-prod-alb-1717206821.us-west-2.elb.amazonaws.com/gs-consumer',
  gsworkorder: 'http://gs-prod-alb-1717206821.us-west-2.elb.amazonaws.com/gs-workorder'*/
};
