import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotComponent } from './common-component/forgot/forgot.component';
import {GloobleGuard} from './glooble.guard';
const routes: Routes = [
  { path: '', loadChildren: () => import('./common-component/login/login.module').then((m) => m.LoginModule)},
  { path: '', loadChildren: () => import('./layout/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule) },
  { path: '', loadChildren: () => import('./layout/user-layout/user-layout.module').then(m => m.UserLayoutModule) },
  { path: 'login', loadChildren: () => import('./common-component/login/login.module').then((m) => m.LoginModule)},
  { path: 'signup', loadChildren: () => import('./common-component/signup/signup.module').then((m) => m.SignupModule)},
  { path: 'signup/thankyou', loadChildren: () => import('./common-component/signup-thankyou/signup-thankyou.module').then((m) => m.SignupThankyouModule)},
  { path: 'forgot', loadChildren: () => import('./common-component/forgot/forgot.module').then((m) => m.ForgotModule)},
  { path: 'reset-password/:id', loadChildren: () => import('./common-component/reset/reset.module').then((m) => m.ResetModule)},
  { path: 'terms-condition', loadChildren: () => import('./common-component/terms/terms.module').then((m) => m.TermsModule)},
  { path: 'privacy-policy', loadChildren: () => import('./common-component/privacy/privacy.module').then((m) => m.PrivacyModule)},
  { path: 'maintenance', loadChildren: () => import('./common-component/maintenance/maintenance.module').then((m) => m.MaintenanceModule)},
  { path: '**', loadChildren: () => import('./common-component/not-found/not-found.module').then((m) => m.NotFoundModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }