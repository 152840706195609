import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RestApiService  {
  constructor(private http: HttpClient) { }

  getCall(url) {
    return this.http
      .get(url)
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  getCallNoBearer(url) {
    const headers = new HttpHeaders({ setbearer: "no-bearer" }); // this just ref for interceptor
    return this.http
      .get(url, { headers: headers })
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  putCall(url,data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8');
    return this.http
      .put(url, data, { headers: headers })
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  putCallHeader(url, data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8');
    return this.http
      .put(url, data, { headers: headers, observe: 'response' })
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  deleteCall(url, data) {
    return this.http
      .delete(url, data)
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  deleteCallWithData(url, data) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http
      .delete(url, options)
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        } else {
          return Observable.throw({});
        }
      });
  }
  postCall(url, data) {
    return this.http
      .post(url, data)
      .map((response: any) => response)
      .catch((error: any) =>{
        if (error.status != '401') {
          return Observable.throw(error)
        } else {
          return Observable.throw({});
        }
      });
  }
  postCallNoBearer(url, data) {
    const headers = new HttpHeaders({ setbearer: "no-bearer" }); // this just ref for interceptor
    return this.http
      .post(url, data, { headers: headers })
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  postWithFormData(url, data: FormData) {
    const headers = new HttpHeaders({ tp: "multipart/form-data" }); // this just ref for interceptor
    return this.http
      .post(url, data, { headers: headers })
      .map((response: any) => response)
      .catch((error: any) => Observable.throw(error));
  }
  postWithFormDataCall(url, data) {
    const formData: FormData = new FormData();
    formData.append("file", data, data.name);
    const headers = new HttpHeaders({ tp: "multipart/form-data" }); // this just ref for interceptor
    return this.http
      .post(url, formData, { headers: headers })
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  signupAuthPostData(url, data) {
    return this.http
      .post(url, data)
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  authPostDataLogin(url, formData) {
    return this.http
      .post(url, formData.toString())
      .map((response: any) => response)
      .catch((error: any) => {
        if (error.status != '401') {
          return Observable.throw(error)
        }else{
          return Observable.throw({});
        }
      });
  }
  // setSession(authResult) {
      
  // } 
  gettoken(){  
    return !!localStorage.getItem("gs_token");  
  }
  getVerification(){  
    return !!localStorage.getItem("gs_verified");  
  }
  downloadExcel(url, model): Observable<any> {
    return new Observable(obs => {
      const bearer = localStorage.getItem('gs_token');
      var oReq = new XMLHttpRequest();
      oReq.open("POST", url, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.setRequestHeader("Authorization", "Bearer " + bearer);
      oReq.responseType = "arraybuffer";

      oReq.onload = function (oEvent) {

        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };

      const body = JSON.stringify(model);
      oReq.send(body);
    });
  }
  refreshToken() {
    let formData = new URLSearchParams();
    formData.set('grant_type', 'refresh_token');
    formData.set('refresh_token', localStorage.getItem('gs_refresh_token'));
    return this.http
      .post(environment.authURL + "/login", formData.toString())
      .map((response: any) => response)
      .catch((error: any) => Observable.throw(error));
  }
  
  //   httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type':  'application/json',
  //   'Authorization': 'Basic ' + btoa('gs-web:gs-web')
  //   })
  //   }
  //   getHttpoption(){
  //     return  {
  //       headers: new HttpHeaders({
  //         'Content-Type':  'application/json',
  //       'Authorization': 'Bearer ' + localStorage.getItem('gs_token')
  //       })
  //       }
  //   }
  // setSession(authResult) {
  //     const expiresAt = moment().add(authResult.expires_in,'second');
  //     localStorage.setItem('gs_token', authResult.access_token);
  //     localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  // }  
  // createEmployee(employee): Observable<Employee> {
  //   return this.http.post<Employee>(environment.apiURL+'/v1/signup', JSON.stringify(employee), this.httpOptions)
  //   .pipe(
  //     catchError(this.handleError)
  //   )
  // }  
  // login(formData){
  //   // return this.http
  //   //   .post(environment.apiURL + '/login', formData.toString())
  //   //   .pipe((response: any) => response)
  //   //   .catch((error: any) => Observable.throw(error));
  //   var htphdrs = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': 'Basic ' + btoa('gs-web:gs-web')
  //     })
  //   }
  //   return this.http.post(environment.apiURL + '/login', formData.toString(), htphdrs).pipe();
  // } 

  // getalluser()
  //   {
  //   return this.http.get(environment.adminUrl + '/v1/gs/user', this.getHttpoption())
  //     .pipe(
  //       catchError(this.handleError)
  //     )
  //   }
  // getallCustomers()
  //   {
  //   return this.http.get(environment.adminUrl + '/v1/gs/user', this.getHttpoption())
  //     .pipe(
  //       catchError(this.handleError)
  //     )
  //   }

  // getmarkup(): Observable<FormArray> {
  //   return this.http.get(environment.adminUrl+'/v1/markup/default',this.getHttpoption())
  //   .pipe(map((albums: Album[]) => {
  //       const fgs = albums.map(Album.asFormGroup);
  //       return new FormArray(fgs);
  //     })
  //   )
  // }
  // saveuser(user)
  // {
  //   return this.http.post(environment.adminUrl+'/v1/gs/user', JSON.stringify(user),this.getHttpoption())
  //   .pipe(
  //     catchError(this.handleError)
  //   )
  // }
  // updateuser(user)
  // {
  //   return this.http.put(environment.adminUrl+'/v1/gs/user', JSON.stringify(user),this.getHttpoption())
  //   .pipe(
  //     catchError(this.handleError)
  //   )
  // }
  // gettoken(){  
  //   return !!localStorage.getItem("gs_token");  
  //   } 
  
  //   deleteuser(ids)
  //   {
  //     return this.http.delete(environment.adminUrl+'/v1/gs/user/'+ids,this.getHttpoption())
  //     .pipe(
  //       catchError(this.handleError)
  //     ) 
  //   }
  
    
  //   deletecustomer(ids)
  //   {
  //     return this.http.delete(environment.adminUrl+'/v1/customer/user/'+ids,this.getHttpoption())
  //     .pipe(
  //       catchError(this.handleError)
  //     ) 
  //   }
  //   /// for customer

  //   getSearch(str)
  //   {
  //     return this.http.get(environment.adminUrl+'/v1/customer/user?email='+str,this.getHttpoption())
  //     .pipe(
  //       catchError(this.handleError)
  //     )
  //   }

 
  //   updatecustomer(data)
  //   {
  //     return this.http.put(environment.adminUrl+'/v1/customer/user', JSON.stringify(data),this.getHttpoption())
  //   .pipe(
  //     catchError(this.handleError)
  //   )
  //   }

  //   createuser(data)
  //   {
  //     return this.http.post(environment.adminUrl+'/v1/customer/user', JSON.stringify(data), this.getHttpoption())
  //   .pipe(
  //     catchError(this.handleError)
  //   )
  //   }

  //   updateform(data)
  //   {
  //     return this.http.put(environment.adminUrl+'/v1/markup/default', JSON.stringify(data),this.getHttpoption())
  //     .pipe(
  //       catchError(this.handleError)
  //     )
  //   }

  


  
  // Error handling 
  // handleError(error) {
  //   console.log(error);
  //    let errorMessage = '';
  //    if(error.error) {
  //      // Get client-side error
  //      errorMessage = error.error.message;
  //    } else {
  //      // Get server-side error
  //      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //    }
  //     Swal.fire(errorMessage);
  //    return throwError(errorMessage);
  // }
  // simpleAlertBox(message) {
  //   Swal.fire(message);
  // }
}