import { Injectable } from '@angular/core';
import { Subject } from "rxjs/Subject";

@Injectable()

export class HeaderRootscopeService {

  constructor() { }
  //sidebag observ
  private getLimitSource = new Subject<any>();
  creditLimit$ = this.getLimitSource.asObservable();
  public cartData: any = {};
  
  changeCreditLimit(component: any) {
    this.getLimitSource.next(component);
  }
}
